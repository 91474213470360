@import 'reset';

body {
    font-family: 'Poppins', sans-serif ;
}

.dark-titlebox {
    height: 214px;
    background-color: #253139;
    padding: 54px 0;

    &__title {
        color: #ffffff;
        text-align: center;
        font-size: 44px;
        font-weight: 600;
        line-height: 58px;
        letter-spacing: -1.1px;
        margin-bottom: 17px;
    }

    &__description {
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        line-height: 24px;
    }
}

.absolute-container {
    box-shadow: 0 0 35px rgba(0, 73, 104, 0.15);

    &--lavender {
        box-shadow: inset 0 0 35px rgba(0, 73, 104, 0.15);
        background-color: #e5eff8;
    }
}

.absolute-image {
    position: absolute;
    height: 528px;
    width: 50%;
    overflow: hidden;

    &--right {
        right: 0;
    }

    &--left {
        left: 0;
    }

    &__container {
        height: 530px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;

        &--left {
            justify-content: flex-end;
        }
    }
}

.introduction {
    min-height: 603px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__container {
        max-width: 900px;
        text-align: center;

        &__title {
            margin-bottom: 40px;
            font-size: 52px;
            font-weight: 800;
            line-height: 64px;
            letter-spacing: -1.3px;

            &--white {
                color: #fff;
            }

            &--blue {
                color: #0e74bb;
            }

            &--shadow {
                text-shadow: 0 5px 10px rgba(38, 63, 1, 0.5);
            }
        }

        &__description {
            color: #0e74bb;
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0.45px;

            &--white {
                color: #fff;
            }

            &--blue {
                color: #0e74bb;
            }
        }
    }
}

.company {
    &__boxes {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;

        &--last-margin {
            margin-bottom: 110px;
        }
    }

    &__text {
        margin: 60px auto 50px;
        max-width: 800px;
        text-align: center;

        &__title {
            color: #253139;
            font-size: 28px;
            font-weight: 700;
            line-height: 32px;
            margin-bottom: 28px;
        }

        &__subtitle {
            color: #253139;
            font-size: 15px;
            margin-bottom: 90px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.38px;
        }
    }

    &__box {
        width: 33%;
        padding: 0 30px 0 80px;
        margin-bottom: 40px;
        position: relative;

        &:first-child,
        &:last-child,
        &:nth-child(3n),
        &:nth-child(4n) {
            border-left: 1px solid #cce1f0;
            border-right: 1px solid #cce1f0;
        }

        &__logo {
            margin-bottom: 30px;

            img {
                max-width: 200px;
            }
        }

        &__text {
            margin-bottom: 25px;

            &:nth-last-of-type(2) {
                margin-bottom: 45px;
            }

            &__title {
                opacity: 0.4;
                color: #253139;
                font-size: 14px;
                font-weight: 700;
                line-height: 24px;
            }

            &__description {
                color: #333333;
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;
            }
        }

        &__no-project-info {
            color: #0e74bb;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
        }

        &__redirection {
            position: absolute;
            bottom: 0;
            color: #0e74bb;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            text-transform: uppercase;

            a {
                color: #0e74bb;
            }

            &:hover {
                color: rgba(14, 116, 187, 0.7);

                a {
                    color: rgba(14, 116, 187, 0.7);
                }
            }
        }
    }
}

.team {
    &__container {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        margin-bottom: 40px;
    }

    &__big {
        width: 50%;
        padding: 0 30px 0 80px;
        margin-bottom: 40px;
        position: relative;
        text-align: center;

        &__image {
            max-width: 432px;
            max-height: 315px;
            overflow: hidden;

            img {
                margin-bottom: 30px;
                width: 100%;
            }
        }

        &__title {
            color: #253139;
            font-size: 21px;
            font-weight: 500;
            line-height: 28px;
            margin-bottom: 20px;
            padding: 0 20px;
        }

        &__subtitle {
            color: #8fc642;
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
            margin-bottom: 20px;
            padding: 0 20px;
        }

        &__description {
            color: #333333;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            padding: 0 10px;
        }
    }

    &__small {
        width: 315px;
        text-align: center;
        margin-bottom: 140px;

        &:first-child,
        &:last-child,
        &:nth-child(3n),
        &:nth-child(4n) {
            border-left: 1px solid #8fc642;
            border-right: 1px solid #8fc642;
        }

        &__title {
            color: #253139;
            font-size: 21px;
            font-weight: 500;
            line-height: 28px;
            margin-bottom: 20px;
            padding: 0 20px;
        }

        &__subtitle {
            color: #8fc642;
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
            margin-bottom: 20px;
            padding: 0 10px;
        }
    }
}

.news-box {
    padding-top: 59px;
    padding-bottom: 80px; // padding: 100px 0 70px;
    background-color: #f5f5f5;

    &__title {
        padding-bottom: 36px;
        float: left;
        color: #1b1f22;
        font-size: 28px;
        font-weight: 600;
        line-height: 58px;
        letter-spacing: -0.7px;
    }

    &__button {
        line-height: 58px;
        float: right;
        color: #2b80c0;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.7px;
        cursor: pointer;
    }

    .loader {
        height: 10px;
        margin: 50px auto;
        font-size: 9px;
        width: 10px;
    }
}

.button-news {
    color: #26a9e0;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.14px;
    width: 217px;
    line-height: 46px;
    margin: 0 auto;
    height: 51px;
    border-radius: 24px;
    border: 2px solid #d1ecf8;
    cursor: pointer;

    &--margined {
        margin-top: 20px;
    }

    &--sidebar {
        margin-bottom: 70px;
        color: #26a9e0;
        font-size: 14px;
        font-weight: 600;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.14px;
        width: 196px;
        height: 51px;
        border-radius: 24px;
        border: 2px solid #d1ecf8;
        text-decoration: none;

        a {
            color: #26a9e0;
        }

        &:hover {
            color: white;
            background: #26a9e0;
            transition: 0.1s ease-in;
            border: 2px solid #26a9e0;
        }
    }

    &:hover {
        background-color: #26a9e0;
        border-color: #26a9e0;
        transition: 0.1s ease-in;
        color: #ffffff;
    }
}

.sidebar {
    margin-top: 86px;

    &__section {
        margin-bottom: 40px;
        min-height: 380px;

        &__underline {
            height: 27px;
            box-shadow: 0 0 24px rgba(0, 0, 0, 0.05);
            opacity: 0.2;
            margin-bottom: 32px;
            background-color: #1b1f22;

            &--green {
                background-color: #8fc642;
            }

            &--blue {
                background-color: #26a9e0;
            }
        }

        &__date {
            padding: 0 20px;
            opacity: 0.5;
            color: #1b1f22;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-transform: uppercase;
            letter-spacing: 3.5px;
        }

        &__title {
            padding: 0 20px;
            margin: 22px 0 34px;
            color: #1b1f22;
            font-size: 21px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: -0.53px;
        }

        &__content {
            padding: 0 20px;
            height: 145px;
            opacity: 0.8;
            color: #1b1f22;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            overflow: hidden;
        }
    }
}

.button-sidebar {
    margin-top: 90px;
    margin-bottom: 110px;
    margin-left: 20px;
    text-align:left;
    color: #0e74bb;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
    cursor: pointer;


    a {
        color: #0e74bb;
    }

    &:hover {
        color: rgba(14, 116, 187, 0.7);

        a {
            color: rgba(14, 116, 187, 0.7);
        }
    }

}

.info {
    height: 603px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--smaller {
        height: 528px;
    }

    &__image {
        max-width: 560px;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    &__background-grass {
        background: url('../images/grass-background.jpg') no-repeat center bottom;
    }

    &__background-gradient {
        background-image: linear-gradient(to left, rgba(143, 198, 66, 0.1) 0%, rgba(100, 169, 223, 0.1) 100%);
    }

    &__background-shadow {
        box-shadow: 0 0 35px rgba(0, 73, 104, 0.15);
    }

    &__text {
        padding: 0 20px 0 60px;

        &--left {
            padding: 0 60px 0 0;
        }

        &__title {
            color: #253139;
            font-size: 28px;
            font-weight: 600;
            line-height: 32px;
            margin-bottom: 30px;
        }

        &__description {
            color: #000;
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.38px;
            margin-bottom: 40px;
        }

        &__redirection {
            color: #26a9e0;
            font-size: 14px;
            font-weight: 600;
            line-height: 46px;
            text-align: center;
            letter-spacing: 0.14px;
            width: 196px;
            height: 51px;
            border-radius: 24px;
            border: 2px solid #d1ecf8;
            text-decoration: none;

            a {
                color: #26a9e0;
            }

            &:hover {
                color: white;
                background: #26a9e0;
                transition: 0.1s ease-in;
                border: 2px solid #26a9e0;
            }
        }
    }
}
@include media-breakpoint-down(lg) {
    .team {
        &__small {
            width: 33%;
        }

        &__big {
            padding: 0 15px;
        }
    }

    .company {
        &__box {
            width: 50%;

            &:nth-child(2n) {
                border-right: 1px solid #cce1f0;
            }

            &:nth-child(4n) {
                border-left: none;
            }
        }
    }

    .absolute-image--left,
    .absolute-image--right {
        display: none;
    }

    .info {
        height: auto;
        margin: 30px 0;

        &__image {
            margin-top: 70px;
        }

        &__text {
            padding: 0;
        }
    }
}
@include media-breakpoint-down(sm) {
    .introduction {
        height: auto;
        margin: 15px 0;
        min-height: unset;

        &__container {
            &__title {
                font-size: 30px;
                margin-bottom: 10px;
                line-height: 40px;
            }

            &__description {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    .company {
        &__text {
            margin: 20px auto;

            &__title {
                margin-bottom: 15px;
            }

            &__subtitle {
                margin-bottom: 30px;
            }
        }

        &__box {
            width: 100%;
            padding: 0 15px;
            border: none !important;
            margin-bottom: 40px;
        }

        &__boxes {
            margin-bottom: 15px;
        }
    }

    .team {
        &__big {
            width: 100%;
            padding: 0;

            &__image {
                margin: 0 auto;
            }
        }

        &__small {
            margin-bottom: 30px;
            border: none !important;
            width: 100%;
        }
    }

    .absolute-image {
        &--left {
            display: none;
        }

        &--right {
            display: none;
        }
    }

    .info {
        margin: 45px 0 15px;

        &__text--left {
            text-align: center;
            padding: 0;
        }

        &__text {
            text-align: center;

            &__title {
                margin-bottom: 15px;
            }

            &__redirection {
                margin: 0 auto;
            }
        }
    }
}



// loader z: https://projects.lukehaas.me/css-loaders/
.loader {
    margin: 100px auto;
    font-size: 25px;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load5 1.1s infinite ease;
    animation: load5 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

@-webkit-keyframes load5 {
    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(#119FDC, 0.2), 2.5em 0em 0 0em rgba(#119FDC, 0.2), 1.75em 1.75em 0 0em rgba(#119FDC, 0.2), 0em 2.5em 0 0em rgba(#119FDC, 0.2), -1.8em 1.8em 0 0em rgba(#119FDC, 0.2), -2.6em 0em 0 0em rgba(#119FDC, 0.5), -1.8em -1.8em 0 0em rgba(#119FDC, 0.7);
    }
    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(#119FDC, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(#119FDC, 0.2), 1.75em 1.75em 0 0em rgba(#119FDC, 0.2), 0em 2.5em 0 0em rgba(#119FDC, 0.2), -1.8em 1.8em 0 0em rgba(#119FDC, 0.2), -2.6em 0em 0 0em rgba(#119FDC, 0.2), -1.8em -1.8em 0 0em rgba(#119FDC, 0.5);
    }
    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(#119FDC, 0.5), 1.8em -1.8em 0 0em rgba(#119FDC, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(#119FDC, 0.2), 0em 2.5em 0 0em rgba(#119FDC, 0.2), -1.8em 1.8em 0 0em rgba(#119FDC, 0.2), -2.6em 0em 0 0em rgba(#119FDC, 0.2), -1.8em -1.8em 0 0em rgba(#119FDC, 0.2);
    }
    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(#119FDC, 0.2), 1.8em -1.8em 0 0em rgba(#119FDC, 0.5), 2.5em 0em 0 0em rgba(#119FDC, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(#119FDC, 0.2), -1.8em 1.8em 0 0em rgba(#119FDC, 0.2), -2.6em 0em 0 0em rgba(#119FDC, 0.2), -1.8em -1.8em 0 0em rgba(#119FDC, 0.2);
    }
    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(#119FDC, 0.2), 1.8em -1.8em 0 0em rgba(#119FDC, 0.2), 2.5em 0em 0 0em rgba(#119FDC, 0.5), 1.75em 1.75em 0 0em rgba(#119FDC, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(#119FDC, 0.2), -2.6em 0em 0 0em rgba(#119FDC, 0.2), -1.8em -1.8em 0 0em rgba(#119FDC, 0.2);
    }
    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(#119FDC, 0.2), 1.8em -1.8em 0 0em rgba(#119FDC, 0.2), 2.5em 0em 0 0em rgba(#119FDC, 0.2), 1.75em 1.75em 0 0em rgba(#119FDC, 0.5), 0em 2.5em 0 0em rgba(#119FDC, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(#119FDC, 0.2), -1.8em -1.8em 0 0em rgba(#119FDC, 0.2);
    }
    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(#119FDC, 0.2), 1.8em -1.8em 0 0em rgba(#119FDC, 0.2), 2.5em 0em 0 0em rgba(#119FDC, 0.2), 1.75em 1.75em 0 0em rgba(#119FDC, 0.2), 0em 2.5em 0 0em rgba(#119FDC, 0.5), -1.8em 1.8em 0 0em rgba(#119FDC, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(#119FDC, 0.2);
    }
    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(#119FDC, 0.2), 1.8em -1.8em 0 0em rgba(#119FDC, 0.2), 2.5em 0em 0 0em rgba(#119FDC, 0.2), 1.75em 1.75em 0 0em rgba(#119FDC, 0.2), 0em 2.5em 0 0em rgba(#119FDC, 0.2), -1.8em 1.8em 0 0em rgba(#119FDC, 0.5), -2.6em 0em 0 0em rgba(#119FDC, 0.7), -1.8em -1.8em 0 0em #ffffff;
    }
}

@keyframes load5 {
    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(#119FDC, 0.2), 2.5em 0em 0 0em rgba(#119FDC, 0.2), 1.75em 1.75em 0 0em rgba(#119FDC, 0.2), 0em 2.5em 0 0em rgba(#119FDC, 0.2), -1.8em 1.8em 0 0em rgba(#119FDC, 0.2), -2.6em 0em 0 0em rgba(#119FDC, 0.5), -1.8em -1.8em 0 0em rgba(#119FDC, 0.7);
    }
    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(#119FDC, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(#119FDC, 0.2), 1.75em 1.75em 0 0em rgba(#119FDC, 0.2), 0em 2.5em 0 0em rgba(#119FDC, 0.2), -1.8em 1.8em 0 0em rgba(#119FDC, 0.2), -2.6em 0em 0 0em rgba(#119FDC, 0.2), -1.8em -1.8em 0 0em rgba(#119FDC, 0.5);
    }
    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(#119FDC, 0.5), 1.8em -1.8em 0 0em rgba(#119FDC, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(#119FDC, 0.2), 0em 2.5em 0 0em rgba(#119FDC, 0.2), -1.8em 1.8em 0 0em rgba(#119FDC, 0.2), -2.6em 0em 0 0em rgba(#119FDC, 0.2), -1.8em -1.8em 0 0em rgba(#119FDC, 0.2);
    }
    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(#119FDC, 0.2), 1.8em -1.8em 0 0em rgba(#119FDC, 0.5), 2.5em 0em 0 0em rgba(#119FDC, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(#119FDC, 0.2), -1.8em 1.8em 0 0em rgba(#119FDC, 0.2), -2.6em 0em 0 0em rgba(#119FDC, 0.2), -1.8em -1.8em 0 0em rgba(#119FDC, 0.2);
    }
    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(#119FDC, 0.2), 1.8em -1.8em 0 0em rgba(#119FDC, 0.2), 2.5em 0em 0 0em rgba(#119FDC, 0.5), 1.75em 1.75em 0 0em rgba(#119FDC, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(#119FDC, 0.2), -2.6em 0em 0 0em rgba(#119FDC, 0.2), -1.8em -1.8em 0 0em rgba(#119FDC, 0.2);
    }
    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(#119FDC, 0.2), 1.8em -1.8em 0 0em rgba(#119FDC, 0.2), 2.5em 0em 0 0em rgba(#119FDC, 0.2), 1.75em 1.75em 0 0em rgba(#119FDC, 0.5), 0em 2.5em 0 0em rgba(#119FDC, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(#119FDC, 0.2), -1.8em -1.8em 0 0em rgba(#119FDC, 0.2);
    }
    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(#119FDC, 0.2), 1.8em -1.8em 0 0em rgba(#119FDC, 0.2), 2.5em 0em 0 0em rgba(#119FDC, 0.2), 1.75em 1.75em 0 0em rgba(#119FDC, 0.2), 0em 2.5em 0 0em rgba(#119FDC, 0.5), -1.8em 1.8em 0 0em rgba(#119FDC, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(#119FDC, 0.2);
    }
    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(#119FDC, 0.2), 1.8em -1.8em 0 0em rgba(#119FDC, 0.2), 2.5em 0em 0 0em rgba(#119FDC, 0.2), 1.75em 1.75em 0 0em rgba(#119FDC, 0.2), 0em 2.5em 0 0em rgba(#119FDC, 0.2), -1.8em 1.8em 0 0em rgba(#119FDC, 0.5), -2.6em 0em 0 0em rgba(#119FDC, 0.7), -1.8em -1.8em 0 0em #ffffff;
    }
}
