/*********INDEX STYLES*********/

.chart {
    min-height: 603px;
    box-shadow: 0 0 35px rgba(0, 73, 104, .15);
    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 63px 0 40px;
        &__title {
            font-size: 21px;
            font-weight: 300;
            line-height: 28px;
            margin-bottom: 24px;
            text-align: center;
            color: #253139;
            &--bolder {
                font-weight: 700;
            }
        }
        &__image {
            margin-bottom: 45px;
            img {
                max-width: 100%;
            }
        }
        &__description {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            max-width: 760px;
            margin: 25px auto;
            text-align: center;
            color: #333;
            &--bolder {
                font-weight: 600;
            }
        }
        &__redirection {
            font-size: 14px;
            font-weight: 600;
            line-height: 46px;
            width: 217px;
            height: 51px;
            margin: 0 auto;
            text-align: center;
            letter-spacing: 0.14px;
            color: #26a9e0;
            border: 2px solid #d1ecf8;
            border-radius: 24px;
            &:hover {
                transition: 0.1s ease-in;
                color: white;
                border: 2px solid #26a9e0;
                background: #26a9e0;
            }
        }
    }
}
