.dark-titlebox {
    height: 214px;
    background-color: #253139;
    padding: 54px 0;
    &__title {
        color: #ffffff;
        text-align: center;
        font-size: 44px;
        font-weight: 600;
        line-height: 58px;
        letter-spacing: -1.1px;
        margin-bottom: 17px;
    }
    &__description {
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        line-height: 24px;
    }
}

.white-titlebox {
    height: 214px;
    background-color: #ffffff;
    padding: 54px 0;
    &__title {
        color: #0e74bb;
        text-align: center;
        font-size: 44px;
        font-weight: 600;
        line-height: 58px;
        letter-spacing: -1.1px;
        margin-bottom: 17px;
    }
    &__description {
        color: #0e74bb;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        line-height: 24px;
    }
}

@include media-breakpoint-down(sm) {
    .dark-titlebox {
        height: auto;
        padding: 15px 0;
        &__title {
            font-size: 30px;
            margin-bottom: 10px;
            line-height: 40px;
        }
        &__description {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
        }
    }
}
