#map {
    height: calc(100vh - 90px);
    max-height: 745px;
}

.infoWindow {
    position: relative;
    width: 350px;
    min-height: 200px;
    padding: 34px 28px 21px;
    &__title {
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        margin-bottom: 10px;
        letter-spacing: .7px;
        text-transform: uppercase;
        color: #333;
    }
    &__text {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        min-height: 100px;
        color: #939393;
    }
    &__redirect {
        a {
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            text-decoration: none;
            text-transform: uppercase;
            color: #0e74bb;
        }
    }
}

.gm-style-iw {
    top: 15px !important;
    left: 25px !important;
    width: 350px !important;
    height: 200px !important;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 0 24px rgba(0, 0, 0, .2);
}
