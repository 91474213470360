.col3-boxes {
    background-color: #f5f5f5;

    &__section {
        background-color: #ffffff;
        padding: 40px 34px;
        margin-bottom: 30px;
        min-height: 380px;
        box-shadow: 0 0 24px rgba(0, 0, 0, 0.05);
        height: 145px;

        &__date {
            opacity: 0.5;
            color: #1b1f22;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-transform: uppercase;
            letter-spacing: 3.5px;
        }

        &__title {
            margin: 22px 0 34px;
            color: #1b1f22;
            font-size: 21px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: -0.53px;
            max-height: 65px;
            overflow: hidden;
        }

        &__content {
            height: 145px;
            opacity: 0.8;
            color: #1b1f22;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            overflow: hidden;
        }

        &:hover {
            box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
            cursor: pointer;
        }
    }
}
