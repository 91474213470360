.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 99999;
    z-index: 40;
    height: 72px;

    &__distance {
        height: 72px;
    }

    &__left {
        float: left;
        z-index: 40;

        &__logo-container {
            padding: 30px;
            left: 0;
            width: 303px;
            z-index: 40;
            height: 104px;
            background: rgba(255,255,255,1);
            box-shadow: 0 -1px 30px rgba(52, 150, 204, 0.2);
            border-radius: 0 0 6px 6px;

            &__logo {
                width: 100%;
                height: 100%;
                background: #ffffff url('../images/main-logo.png') no-repeat center;
                background-size: contain;
            }
        }
    }

    &__right {
        float: right;

        &__menu {
            display: inline-block;
            float: left;

            &__container {
                &__item {
                    display: inline-block;
                    line-height: 72px;
                    opacity: 0.8;
                    color: #253139;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: -0.07px;
                    padding-left: 20px;

                    &.active,
                    &:hover {
                        color: #369bcf;

                        a {
                            color: #369bcf;
                        }
                    }

                    &.active {
                        opacity: 1;
                    }

                    a {
                        color: #253139;
                    }
                }
            }
        }

        &__language {
            display: inline-block;
            float: right;
            margin-left: 20px;

            &__container {
                margin: 17px 0;

                &__item {
                    display: inline-block;
                    width: 38px;
                    height: 38px;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 34px;
                    text-align: center;
                    color: #535c62;
                    border: 2px solid #fff;

                    &:hover {
                        color: #369bcf;
                        border-radius: 6px;
                        border: 2px solid #d1ecf8;

                        a {
                            color: #369bcf;
                        }
                    }

                    &.active {
                        border-radius: 6px;
                        border: 2px solid #d1ecf8;
                        color: #369bcf;
                        text-transform: uppercase;

                        a {
                            color: #369bcf;
                        }
                    }
                }
            }
        }
    }
}

.menu-icon {
    display: none;
}
@include media-breakpoint-down(md) {
    .header {
        &__left {
            &__logo-container {
                height: 72px;
                box-shadow: unset;
                width: 200px;
                padding: 0;
            }
        }
    }
}
@include media-breakpoint-down(sm) {
    .header {
        &__left {
            &__logo-container {
                height: 72px;
                width: 200px;
                padding: 10px;
                box-shadow: none;
            }
        }

        &__right {
            float: none;

            &__menu {
                width: 100%;

                &__container {
                    &__item {
                        display: none;
                        padding-right: 0;
                        line-height: 50px;
                        text-align: center;
                        border-bottom: 1px solid #369bcf;
                    }
                }
            }

            &__language {
                display: none;
            }
        }
    }

    .menu-icon {
        display: block;
        width: 35px;
        height: 30px;
        margin: 20px 0 10px;
        border-radius: 5px;
        border: 0 solid #369bcf;
        position: absolute;
        right: 10px;
        top: 3px;

        span {
            height: 4px;
            width: 100%;
            position: absolute;
            left: 0;
            background: #369bcf;

            &:nth-child(1) {
                top: 5px;
            }

            &:nth-child(2) {
                top: 15px;
            }

            &:nth-child(3) {
                top: 25px;
            }
        }
    }

    .responsive {
        .header__right__menu__container__item {
            background: #ffffff;
            opacity: 1;
            display: block;
        }
    }
}
