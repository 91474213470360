.article {
    margin: 72px 0;

    &__title {
        margin: 0 0 28px;
        color: #1b1f22;
        font-size: 28px;
        font-weight: 700;
        line-height: 58px;
        letter-spacing: -0.7px;
    }

    &__text {
        color: rgba(#51585c, 0.8);
        font-size: 16px;
        font-weight: 500;
        line-height: 36px;
        word-wrap: break-word;

        ul {
            list-style-type: disc;
            padding-left: 50px;
        }

        table {
            border: 1px;

            td {
                border: 1px;
                padding: 3px;
            }
        }

        img {
            max-width: 100%;
        }
    }
}
