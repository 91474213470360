$jumbotron-height: calc(100vh - 72px);
$jumbotron-max-height: 732px;
$jumbotron-min-height: 630px;

.jumbotron {
    position: relative;

    &__overlay {
        height: $jumbotron-height;
        max-height: $jumbotron-max-height;
        min-height: $jumbotron-min-height;

        &__header {
            position: absolute;
            top: 175px;
            z-index: 30;
            color: #ffffff;

            &__title {
                margin-bottom: 36px;
                max-width: 596px;
                font-size: 52px;
                font-weight: 700;
                line-height: 60px;
                letter-spacing: -1px;
            }

            &__subtitle {
                max-width: 457px;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.3px;
            }
        }

        &__counter-box {
            position: absolute;
            z-index: 30;
            bottom: 0;

            &__counter {
                position: relative;
                display: inline-block;
                min-width: 270px;
                height: 96px;
                padding: 12px 16px;
                margin-right: 2px;
                border-radius: 6px 6px 0 0;
                background-color: rgba(0,0,0,0.5);
                color: #ffffff;

                &__dot {
                    position: absolute;
                    right: 6px;
                    top: 5px;
                    width: 8px;
                    height: 8px;
                    box-shadow: 0 0 8px #28aa7c;
                    background-color: #28aa7c;
                    border-radius: 4px;
                    animation-name: blinking;
                    animation-duration: 1s;
                    animation-iteration-count: infinite;
                    @keyframes blinking {
                        0% {
                            background-color: #28aa7c;
                            box-shadow: 0 0 8px #28aa7c;
                        }

                        50% {
                            background-color: #5ed9ae;
                            box-shadow: 0 0 8px #5ed9ae;
                        }

                        100% {
                            background-color: #28aa7c;
                            box-shadow: 0 0 8px #28aa7c;
                        }
                    }
                }

                &__title {
                    max-width: 240px;
                    height: 19px;
                    font-size: 12px;
                    font-weight: 700;
                    text-transform: uppercase;
                }

                &__number {
                    margin-bottom: 5px;
                    display: inline-block;
                    height: 41px;
                    font-size: 36px;
                    font-weight: 900;
                    text-transform: uppercase;
                    font-family: 'Roboto Mono', monospace;

                    .odometer-digit {
                        vertical-align: initial !important;
                    }
                }

                &__unit {
                    display: inline-block;
                    height: 23px;
                    font-size: 18px;
                    font-weight: 900;
                    vertical-align: bottom;
                    margin-bottom: 7px;
                }

                &__date {
                    width: 240px;
                    height: 19px;
                    opacity: 0.5;
                    font-weight: 700;
                    font-size: 13px;
                }
            }
        }
    }

    &__image {
        position: absolute;
        top: 0;
        height: $jumbotron-height;
        max-height: $jumbotron-max-height;
        min-height: $jumbotron-min-height;
        width: 100%;
        left: 0;
        background: url('../images/environment-background.png') no-repeat center;
        background-size: cover;
    }

    &__gradient {
        position: absolute;
        top: 0;
        height: $jumbotron-height;
        max-height: $jumbotron-max-height;
        min-height: $jumbotron-min-height;
        width: 100%;
        left: 0;
        z-index: 10;
        background-size: cover;
        background: linear-gradient(to right, rgba(80,163,201,1) 0%,rgba(80,163,201,1) 25%,rgba(80,163,201,0.03) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=  '#50a3c9', endColorstr='#0850a3c9', GradientType=1);
        // background-image: linear-gradient(to right, #50a3c9 0%, rgba(38, 169, 224, 0) 100%);
    }
    @include media-breakpoint-down(sm) {
        .jumbotron {
            &__overlay {
                &__header {
                    position: absolute;
                    top: 60px;
                }

                &__counter-box {
                    &__counter {
                        border-radius: 6px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}
