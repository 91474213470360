.footer {
    height: auto;
    box-shadow: inset 0 10px 15px rgba(0, 73, 104, 0.2);
    background-color: #eff3f5;
    padding: 100px 0;
    &__box {
        padding: 20px 0;
        text-align: center;
        &__title {
            color: #0e74bb;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 22px;
            text-transform: uppercase;
            letter-spacing: 0.7px;
        }
        &__content {
            color: #555;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .footer {
        padding: 30px 0;
    }
}
